import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { Box, Flex } from "@rebass/grid/emotion"

// entire footer:
export const FooterContainer = styled.footer`
  background: ${theme.wild.color.master.secondary.alt};
  &[data-mousedown] {
    *:focus {
      outline: none !important;
    }
  }
  input:focus-visible {
    outline: none !important;
  }
  .section-header {
    color: ${theme.wild.color.master.primary.secondary};
    letter-spacing: 0.5px;
    margin: 0 0 24px 0;
  }
`
export const MainBox = styled(Box)`
  padding-top: 32px;
`
export const FooterWrap = styled.div`
  flex-wrap: wrap;
  padding: 40px 0;
  display: flex;
  max-width: 100%;

  @media (min-width: 1024px) {
    padding: 24px 0 24px 0;
  }
`

export const LogoWrap = styled.div`
  padding: 0 0 25px 0;
  margin-left: 0;
  @media (min-width: 768px) {
    padding: 0 8px 32px 0;
  }
`

export const FooterWrapNoPad = styled.div`
  padding: 0 25px;
  display: flex;
`

export const Sitemap = styled.a`
  position: absolute;
  bottom: -20px;
  left: 0;
  > * {
    text-decoration: underline;
  }
`

export const StyledLeftSide = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 36%;
  }

  section {
    width: 100%;

    padding: 0;
    @media (min-width: 1024px) {
      width: 70%;
    }
    @media (min-width: 768px) {
      width: 85%;
    }
    h4 {
      color: ${theme.wild.color.master.base.black};
      margin: 0 0 5px 0;
      padding-bottom: 8px;
      @media (min-width: 768px) {
        padding-bottom: 0;
      }
    }
    p {
      color: ${theme.wild.color.master.base.black};
      letter-spacing: 0;
      line-height: 21px;
      padding-bottom: 25px;

      @media (min-width: 768px) {
        padding-bottom: 0;
      }
      &.survey:first-letter {
        vertical-align: 5px;
        font-size: 0.7em;
      }
    }
  }
  section:first-of-type,
  section:nth-of-type(2) {
    @media (min-width: 768px) {
      padding: 0 0 32px 0;
    }
  }

  .nowrap {
    white-space: nowrap;
  }
`
export const StyledRightSide = styled.div`
  padding: 74px 0 0 0;
  width: 50%;
  @media (min-width: 1024px) {
    width: 64%;
  }
`
export const StyledLinksSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const StyledCopyrightWrapper = styled(Flex)`
  width: 100%;
  margin-top: 80px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`

export const StyledCopyright = styled(Box)`
  position: relative;
  color: ${theme.wild.color.master.base.black};
  line-height: 130%;
  letter-spacing: 0;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 33%;
    margin-right: 17%;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
    width: 30%;
    margin-right: 5.5%;
  }

  div.text {
    width: 100%;
    @media (min-width: 768px) {
      width: 70%;
    }
  }
`

export const StyledTerms = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${theme.wild.color.master.secondary.secondary};
  border-radius: 4px;
  min-width: 26%;
  height: 64px;
  padding: 0 12px;

  @media (min-width: 768px) {
    min-width: 280px;
  }

  @media (min-width: 1024px) {
    padding: 0 19px;
  }

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
    background: ${theme.wild.color.master.secondary.secondary};
  }
`

export const StyledLinks = styled.div`
  width: 50%;
  position: relative;
  @media (min-width: 768px) {
    width: 33%;
  }

  &.col-wellness {
    width: 50%;
    @media (min-width: 480px) {
      width: 33%;
    }
    @media (min-width: 640px) {
      width: 25%;
    }
    @media (min-width: 768px) {
      width: 33%;
    }
    @media (min-width: 1024px) {
      width: 25%;
    }
  }
  &.col-company {
    width: 50%;
    @media (min-width: 480px) {
      width: 33%;
    }
    @media (min-width: 640px) {
      width: 25%;
    }
    @media (min-width: 768px) {
      width: 33%;
    }
    @media (min-width: 1024px) {
      width: 25%;
    }
  }
  &.col-legal-x {
    width: 50%;
    @media (min-width: 480px) {
      display: none;
    }
  }
  &.col-legal-s {
    width: 33%;
    display: none;
    @media (min-width: 480px) {
      display: block;
    }
    @media (min-width: 640px) {
      width: 50%;
    }
  }
  &.col-legal-l {
    width: 33%;
    @media (min-width: 1024px) {
      width: auto;
    }
  }

  > h4 {
    color: ${theme.wild.color.men.primary.default};
    line-height: 19px;
    margin: 0 0 24px 0;
    text-transform: capitalize;
    letter-spacing: 0.5px;
  }
  > .link {
    min-width: 111px;
    line-height: 14px;
    margin-bottom: 16px;
    &[class$="personal information"] {
      min-width: 135px;
    }
    a {
      text-transform: capitalize;
      color: ${theme.wild.color.master.base.black};
    }

    p {
      display: inline-block;
    }
  }
  > .link:last-of-type {
    margin: 0;
  }
  .survey:first-letter {
    vertical-align: 5px;
    font-size: 0.7em;
  }
`

export const FormEmailAddress = styled.form`
  width: 100%;

  &.email-x {
    margin-bottom: 40px;
    display: block;
    @media (min-width: 640px) {
      display: none;
    }
  }

  &.email-s {
    position: absolute;
    bottom: 0;
    display: none;
    @media (min-width: 640px) {
      position: static;
      display: block;
      margin-top: 16px;
      > p {
        width: 211px;
      }
    }
  }

  &.email-m {
    display: block;
    width: 95%;
    @media (min-width: 400px) {
      width: 85%;
    }
    @media (min-width: 1024px) {
      display: none;
    }
  }
  &.email-l {
    display: none;
    @media (min-width: 1024px) {
      margin-top: 16px;
      display: block;
    }
  }

  p {
    color: ${theme.wild.color.master.base.black};
    padding-bottom: 8px;

    @media (min-width: 768px) {
      padding-bottom: 14px;
    }
  }
  div.form-group {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 48px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid ${theme.wild.color.master.base.alt.default};
    overflow: hidden;
    @media (min-width: 768px) {
      height: 56px;
    }
  }
  input.form-email {
    border-radius: 0 !important;
    box-sizing: border-box;
    width: 80%;
    height: 100%;
    padding: 14px 0 14px 16px;
    line-height: 16px;
    color: ${theme.wild.color.master.base.black};
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    &::placeholder {
      font-size: 12px;
      line-height: 16px;
      color: ${theme.wild.color.master.base.alt.alt};
      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  button.form-submit {
    box-sizing: border-box;
    width: 20%;
    padding: 0;
    height: 99%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 16px;
      height: 16px;

      @media (min-width: 768px) {
        width: 22px;
        height: 22px;
      }
    }
  }
`

// email validation error element
export const EmailError = styled.div`
  padding-top: 5px;
  > p {
    color: ${theme.wild.color.master.functional.error} !important;
  }
`

export const DesktopWrapper = styled.div`
  display: none;
  max-width: 100% !important;
  flex-direction: column;
  .container-border {
    border-bottom: 1px solid rgb(228, 228, 228);
    width: 100%;
  }
  @media (min-width: 768px) {
    display: flex;
  }
`
export const MobileWrapper = styled.div`
  display: block;
  .container-border {
    border-bottom: 1px solid rgb(228, 228, 228);
    width: 100%;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export const StyledMobileLinks = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 30px;
`
export const SignUpTerms = styled.p`
  font-size: 0;
  padding-bottom: 0 !important;
  p,
  span,
  a {
    font-size: 12px;
    line-height: 1.3;
    color: rgba(67, 86, 82, 1);
    @media (min-width: 768px) {
      font-size: 10px;
    }
    @media (min-width: 1024px) {
      font-size: 12px;
    }
  }
  a {
    text-decoration: underline;
  }
`
